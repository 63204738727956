import axios from 'axios';
import {environmentConfig} from '../config/environmentConfig';

export default class DashboardService {
    static async registerNewUser(credentials) {
        return await axios.post(`${environmentConfig.internalDashboardService}/register-new-user`, credentials);
    }

    static async getUserCredentials(email) {
        return await axios.post(`${environmentConfig.internalDashboardService}/login-user`, {
            email: email
        });
    }


    static async updatePassword(credentials) {
        return await axios.post(`${environmentConfig.internalDashboardService}/update-user-password`, credentials);
    }

    static async getStaffDetails() {
        return await axios.get(`${environmentConfig.internalDashboardService}/get-all-staff-details`);
    }

    static async submitLeaveApplication(userData, leaveTypeSelected, startDate, endDate, hoursLeave) {
        return await axios.post(`${environmentConfig.internalDashboardService}/request-leave`, {
            surname: userData.Surname,
            msisdn: userData.Msisdn,
            leaveType: leaveTypeSelected,
            startDate: startDate,
            endDate: endDate,
            durationHours: hoursLeave,
        });
    }

    static async sendSMS(smsData) {
        let header = {endpoint: `${environmentConfig.vodacomUtilsService}/sms/sendSms`}
        try {
            return await axios.post(`${environmentConfig.vodacomUtilsService}/utils/proxy`, {
                    msisdn: smsData.msisdn,
                    network: 'Vodacom',
                    text: smsData.text,
                }, {headers: header}
            );
        } catch (error) {
            console.log(JSON.stringify(error));
        }
    }

    static async getAllEmployeeRequestedLeave() {
        const response = await axios.get(`${environmentConfig.internalDashboardService}/get-all-employee-requested-leave`);
        return response.data
    }

    static async approveDenyEmployeeLeave(leaveId, approvedBoolean) {
        return await axios.post(`${environmentConfig.internalDashboardService}/approve-deny-employee-leave`, {
            leaveId: leaveId,
            approved: approvedBoolean,
        });
    }

}
