import dotenv from 'dotenv';

dotenv.config();

export const productionConfig = {
    internalDashboardService: `${process.env.VUE_APP_INTERNAL_DASHBOARD_SERVICE}`,
    vodacomUtilsService: `${process.env.VUE_APP_VODACOM_UTILS_SERVICE}`,
    blobSas: `${process.env.VUE_APP_SAS}`,
    azureAccount: `${process.env.VUE_APP_AZURE_ACCOUNT}`,
    azureContainerName: `${process.env.VUE_APP_CONTAINER_NAME}`,
    internalDashboardUrl : `${process.env.VUE_APP_PENROSE_INTERNAL_DASHBOARD_URL}`,
};

export const stagingConfig = {
    internalDashboardService: `${process.env.VUE_APP_INTERNAL_DASHBOARD_SERVICE}`,
    vodacomUtilsService: `${process.env.VUE_APP_VODACOM_UTILS_SERVICE}`,
    blobSas: `${process.env.VUE_APP_SAS}`,
    azureAccount: `${process.env.VUE_APP_AZURE_ACCOUNT}`,
    azureContainerName: `${process.env.VUE_APP_CONTAINER_NAME}`,
    internalDashboardUrl : `${process.env.VUE_APP_PENROSE_INTERNAL_DASHBOARD_URL}`,
};

const environment = getEnvironment()

export const environmentConfig = getEnvObject();

function getEnvironment() {
    let base_url = window.location.origin;
    if (base_url.includes('staging')) return 'staging'
    if (base_url.includes('localhost')) return 'development'
    else return 'production'
}

function getEnvObject() {
    if (environment === 'staging') return stagingConfig
    if (environment === 'development') return productionConfig
    if (environment === 'production') return productionConfig
}
